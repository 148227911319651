window.Site = {

    // We probably don't need that much stuff
    figures : document.querySelectorAll('figure'),
    images  : document.querySelectorAll('img'),
    active  : null,


    liftoff : _ => {
        Site.zoomInOnClick()
        Site.zoomOutOnClick()
        Site.showNext()
        Site.showPrev()
    },

    // On click zoom in
    zoomin  : (figure) => {

        // Add the zoom class
        figure.firstElementChild.classList.add('zoomed');

        document.body.style.overflow = 'hidden';

        // Swap the small and big image
        Site.swapSizes(figure.firstElementChild)

        // Capture the active slide
        Site.active = figure

    },
    zoomout : (image)  => {

        // Do nothing if we don't have a class already
        if (!image.classList.contains('zoomed')) return;

        document.body.style.overflow = 'auto';

        // Remove the class
        image.classList.remove('zoomed')

        // Swap the two sizes
        Site.swapSizes(image)

        // Unset the active slide
        Site.active = null

    },

    // Swap the two sizes
    swapSizes : (image) => {

        // Swap the small and big image
        let cropped  = image.src;
        let original = image.dataset.src;

        // Set the two attributes
        image.setAttribute('src' , original)
        image.setAttribute('data-src' , cropped)
    },

    // On click zoom in
    zoomInOnClick : () => {
        Site.figures.forEach(figure => {
            figure.addEventListener('click' , (event) => {
                event.stopPropagation()
                Site.zoomin(figure)
            })
        })
    },

    // On click zoom out
    zoomOutOnClick : () => {
        Site.images.forEach(image => {
            image.addEventListener('click' , (event) => {
                event.stopPropagation()
                Site.zoomout(image)
            })
        })
    },

    // Next Arrow Click
    showNext : () => {
        window.addEventListener("keyup", (event) => {

            console.log(Site.active)
            console.log(event.keyCode)

            // If the wrong button is clicked or the image is not zoomed do nothing
            if (event.keyCode != 39 || !Site.active) return;

            // We need to grab the next sibling
            let next = Site.active.nextElementSibling ?? Site.active.parentElement.firstElementChild;

            // We need to oom out the current image and zoom in the next
            Site.zoomout(Site.active.firstElementChild)
            Site.zoomin(next)

        })
    },

    // Prev Arrow Click
    showPrev : () => {
        window.addEventListener("keyup", (event) => {

            // If the wrong button is clicked or the image is not zoomed do nothing
            if (event.keyCode != 37 || !Site.active) return;

            // We need to grab the previous sibling
            let prev = Site.active.previousElementSibling ?? Site.active.parentElement.lastElementChild;

            Site.zoomout(Site.active.firstElementChild)
            Site.zoomin(prev)

        })
    }


};

Site.liftoff();